import React from "react"
// import Layout from '../components/layout'
import 'bootstrap/dist/css/bootstrap.min.css';
import '/static/css/tooplate-style.css';
// import * as boostrap from 'bootstrap';
import { Helmet } from "react-helmet"
import favicon from '/static/favicon.ico'

export default function Home() {
  return <div>
    <Helmet>
      <meta charset="UTF-8" />
      <meta content="IE=Edge" http-equiv="X-UA-Compatible" />
      {/* <title>{pageTitle} {pageTitle ? '-' : ''} {data.site.siteMetadata.title}</title> */}
      <title>YouTube Video Organizer - Uolia</title>
      <meta name="description" content="Organize your YouTube videos in libraries, folders, and add bookmarks, notes and TODOs." />
      <link rel="icon" href={favicon} />
    </Helmet>
    {/* <!-- MENU --> */}
    <nav class="navbar navbar-expand-sm navbar-light">
      <div class="container">
        <a class="navbar-brand" href="/">Uolia</a>

        {/* <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
          <span class="navbar-toggler-icon"></span>
          <span class="navbar-toggler-icon"></span>
        </button> */}

        {/* <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav mx-auto">
            <li class="nav-item">
              <a href="#about" class="nav-link"><span data-hover="About">About</span></a>
            </li>
            <li class="nav-item">
              <a href="#project" class="nav-link"><span data-hover="Projects">Projects</span></a>
            </li>
            <li class="nav-item">
              <a href="#resume" class="nav-link"><span data-hover="Resume">Resume</span></a>
            </li>
            <li class="nav-item">
              <a href="#contact" class="nav-link"><span data-hover="Contact">Contact</span></a>
            </li>
          </ul> */}

          {/* <ul class="navbar-nav ml-lg-auto">
            <div class="ml-lg-4">
              <div class="color-mode d-lg-flex justify-content-center align-items-center">
                <i class="color-mode-icon"></i>
                Color mode
              </div>
            </div>
          </ul> */}
        {/* </div> */}
      </div>
    </nav>

    {/* <!-- ABOUT --> */}
    <section class="about full-screen d-lg-flex justify-content-center align-items-center" id="about">
      <div class="container">
        <div class="row">

          <div class="col-lg-7 col-md-12 col-12 d-flex align-items-center">
            <div class="about-text">
              <small class="small-text">YouTube <span class="mobile-block">Video Organizer</span></small>
              <h1 class="animated animated-text">
                <span class="me-2">Hi, Uolia is </span>
                <div class="animated-info">
                  <span class="animated-item"> Productivity</span>
                  <span class="animated-item"> Organization</span>
                  <span class="animated-item"> Portability</span>
                </div>
              </h1>

              <p>Organize your YouTube videos in libraries, folders, and add bookmarks, notes and TODOs. Courses, tutorials or whatever content you want.</p>

              <div class="custom-btn-group mt-4">
                <a href="https://play.google.com/store/apps/details?id=com.uolia.app" target="_blank" class="btn me-lg-2 custom-btn"><i class='uil uil-file-alt'></i> Android version</a>
                <a href="https://app.uolia.com" target="_blank" class="btn custom-btn custom-btn-bg custom-btn-link">Web version</a>
              </div>
            </div>
          </div>

          <div class="col-lg-5 col-md-12 col-12">
            <div class="about-image svg">
              <img src="images/undraw/undraw_software_engineer_lvl5.svg" class="img-fluid" alt="svg image" />
            </div>
          </div>

        </div>
      </div>
    </section>

    {/* <!-- PROJECTS --> */}
    <section class="project py-5" id="project">
      <div class="container">

        <div class="row">
          <div class="col-lg-11 text-center mx-auto col-12">

            <div class="col-lg-8 mx-auto">
              <h2>Synchronize your notes and bookmarks on all your devices</h2>
            </div>

            <div class="owl-carousel owl-theme">
              <div class="item">
                <div class="project-info">
                  <img src="images/project/project-image01.png" class="img-fluid" alt="project image" />
                </div>
              </div>

              {/* <div class="item">
                <div class="project-info">
                  <img src="images/project/project-image02.png" class="img-fluid" alt="project image" />
                </div>
              </div>

              <div class="item">
                <div class="project-info">
                  <img src="images/project/project-image03.png" class="img-fluid" alt="project image" />
                </div>
              </div>

              <div class="item">
                <div class="project-info">
                  <img src="images/project/project-image04.png" class="img-fluid" alt="project image" />
                </div>
              </div>

              <div class="item">
                <div class="project-info">
                  <img src="images/project/project-image05.png" class="img-fluid" alt="project image" />
                </div>
              </div> */}
            </div>

          </div>
        </div>
      </div>
    </section>

    {/* <!-- FEATURES --> */}
    {/* <section class="resume py-5 d-lg-flex justify-content-center align-items-center" id="resume">
      <div class="container">
        <div class="row">

          <div class="col-lg-6 col-12">
            <h2 class="mb-4">Experiences</h2>

            <div class="timeline">
              <div class="timeline-wrapper">
                <div class="timeline-yr">
                  <span>2019</span>
                </div>
                <div class="timeline-info">
                  <h3><span>Project Manager</span><small>Best Studio</small></h3>
                  <p>Proin ornare non purus ut rutrum. Nulla facilisi. Aliquam laoreet libero ac pharetra feugiat. Cras ac fermentum nunc, a faucibus nunc.</p>
                </div>
              </div>

              <div class="timeline-wrapper">
                <div class="timeline-yr">
                  <span>2018</span>
                </div>
                <div class="timeline-info">
                  <h3><span>UX Designer</span><small>Digital Ace</small></h3>
                  <p>Fusce rutrum augue id orci rhoncus molestie. Nunc auctor dignissim lacus vel iaculis.</p>
                </div>
              </div>

              <div class="timeline-wrapper">
                <div class="timeline-yr">
                  <span>2016</span>
                </div>
                <div class="timeline-info">
                  <h3>UI Freelancer</h3>
                  <p>Sed fringilla vitae enim sit amet cursus. Sed cursus dictum tortor quis pharetra. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>
                </div>
              </div>

              <div class="timeline-wrapper">
                <div class="timeline-yr">
                  <span>2014</span>
                </div>
                <div class="timeline-info">
                  <h3>Junior Designer<small>Crafted Co.</small></h3>
                  <p>Cras scelerisque scelerisque condimentum. Nullam at volutpat mi. Nunc auctor ipsum eget magna consequat viverra.</p>
                </div>
              </div>

            </div>
          </div>

          <div class="col-lg-6 col-12">
            <h2 class="mb-4 mobile-mt-2">Educations</h2>

            <div class="timeline">
              <div class="timeline-wrapper">
                <div class="timeline-yr">
                  <span>2017</span>
                </div>
                <div class="timeline-info">
                  <h3><span>Mobile Web</span><small>Master Design</small></h3>
                  <p>Please tell your friends about Tooplate website. That would be very helpful. We need your support.</p>
                </div>
              </div>

              <div class="timeline-wrapper">
                <div class="timeline-yr">
                  <span>2015</span>
                </div>
                <div class="timeline-info">
                  <h3><span>User Interfaces</span><small>Creative Agency</small></h3>
                  <p><a rel="nofollow" href="https://www.facebook.com/tooplate">Tooplate</a> is a great website to download HTML templates without any login or email.</p>
                </div>
              </div>

              <div class="timeline-wrapper">
                <div class="timeline-yr">
                  <span>2013</span>
                </div>
                <div class="timeline-info">
                  <h3><span>Artwork Design</span><small>New Art School</small></h3>
                  <p>You can freely use Tooplate's templates for your business or personal sites. You cannot redistribute this template without a permission.</p>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </section> */}

    {/* <!-- CONTACT --> */}
    {/* <section class="contact py-5" id="contact">
      <div class="container">
        <div class="row">

          <div class="col-lg-5 mr-lg-5 col-12">
            <div class="google-map w-100">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12088.558402180099!2d-73.99373482142036!3d40.75895421922642!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25855b8fb3083%3A0xa0f9aef176042a5c!2sTheater+District%2C+New+York%2C+NY%2C+USA!5e0!3m2!1sen!2smm!4v1549875377188" width="400" height="300" frameborder="0" style={{ "border": 0 }} allowfullscreen></iframe>
            </div>

            <div class="contact-info d-flex justify-content-between align-items-center py-4 px-lg-5">
              <div class="contact-info-item">
                <h3 class="mb-3 text-white">Say hello</h3>
                <p class="footer-text mb-0">010 020 0960</p>
                <p><a href="mailto:hello@company.co">hello@company.co</a></p>
              </div>

              <ul class="social-links">
                <li><a href="#" class="uil uil-dribbble" data-toggle="tooltip" data-placement="left" title="Dribbble"></a></li>
                <li><a href="#" class="uil uil-instagram" data-toggle="tooltip" data-placement="left" title="Instagram"></a></li>
                <li><a href="#" class="uil uil-youtube" data-toggle="tooltip" data-placement="left" title="Youtube"></a></li>
              </ul>
            </div>
          </div>

          <div class="col-lg-6 col-12">
            <div class="contact-form">
              <h2 class="mb-4">Interested to work together? Let's talk</h2>

              <form action="" method="get">
                <div class="row">
                  <div class="col-lg-6 col-12">
                    <input type="text" class="form-control" name="name" placeholder="Your Name" id="name" />
                  </div>

                  <div class="col-lg-6 col-12">
                    <input type="email" class="form-control" name="email" placeholder="Email" id="email" />
                  </div>

                  <div class="col-12">
                    <textarea name="message" rows="6" class="form-control" id="message" placeholder="Message"></textarea>
                  </div>

                  <div class="ml-lg-auto col-lg-5 col-12">
                    <input type="submit" class="form-control submit-btn" value="Send Button" />
                  </div>
                </div>
              </form>
            </div>
          </div>

        </div>
      </div>
    </section> */}

    {/* <!-- FOOTER --> */}
    <footer class="footer py-5">
      <div class="container">
        <div class="row">

          <div class="col-lg-12 col-12">
            <p class="copyright-text text-center">Copyright &copy; 2021 Uolia . All rights reserved</p>
          </div>

        </div>
      </div>
    </footer>
  </div>
}
